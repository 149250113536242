exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-credits-js": () => import("./../../../src/pages/credits.js" /* webpackChunkName: "component---src-pages-credits-js" */),
  "component---src-pages-downloads-js": () => import("./../../../src/pages/downloads.js" /* webpackChunkName: "component---src-pages-downloads-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-inv-viewer-js": () => import("./../../../src/pages/inv-viewer.js" /* webpackChunkName: "component---src-pages-inv-viewer-js" */),
  "component---src-pages-region-requester-js": () => import("./../../../src/pages/region-requester.js" /* webpackChunkName: "component---src-pages-region-requester-js" */),
  "component---src-pages-render-js": () => import("./../../../src/pages/render.js" /* webpackChunkName: "component---src-pages-render-js" */),
  "component---src-pages-statistics-js": () => import("./../../../src/pages/statistics.js" /* webpackChunkName: "component---src-pages-statistics-js" */),
  "component---src-pages-testdyn-js": () => import("./../../../src/pages/testdyn.js" /* webpackChunkName: "component---src-pages-testdyn-js" */)
}

